import React, { useEffect, useState } from 'react'
import { callApi } from '../../../utils/api-utils'
import { Breadcrumb, Button, Form } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import toast from 'react-hot-toast'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const editEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/coordinator/update/:id'
}
const viewEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/coordinator/view/:id'
}

const EditCoordinator = props => {
  const [addFiled, setAddFiled] = useState({})

  const [validationErrors, setValidationErrors] = useState({
    email: '',
    phone: ''
  })

  const [isDataFetched, setIsDataFetched] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const validateEmail = email => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(email)
  }

  const validatePhone = phone => {
    const phoneRegex = /^\+?\d{1,2}[-\s]?\(?\d{1,4}\)?[-\s]?\d{1,4}[-\s]?\d{1,4}$/
    return phoneRegex.test(phone)
  }

  const handleSubmit = async e => {
    setIsSubmitted(true)
    e.preventDefault()

    let errors = {
      email: '',
      phone: '',
      password: ''
    }
    if (!validateEmail(addFiled.email)) {
      errors.email = 'Invalid email format'
    }
    if (!validatePhone(addFiled.phone)) {
      errors.phone = 'Invalid phone number format'
    }

    setValidationErrors(errors)

    if (validateEmail(addFiled.email) && validatePhone(addFiled.phone)) {
      await callApi({ uriEndPoint: editEndPoint, body: addFiled, pathParams: { id: props?.match?.params?.id || null } })
        .then(res => {
          toast.success(res?.message ?? 'Coordinator updated successfully!')
          window.history.back()
        })
        .catch(e => toast.error(e?.message ?? 'Coordinator not updated!'))
    }
  }

  const handelChange = e => {
    const { name, value } = e.target

    // Update form field value
    setAddFiled({ ...addFiled, [name]: value })

    // Validate input dynamically on change (only after user interaction)
    let newValidationErrors = { ...validationErrors }

    if (name === 'email') {
      newValidationErrors.email = validateEmail(value) ? '' : 'Invalid email format'
    }
    if (name === 'phone') {
      newValidationErrors.phone = validatePhone(value) ? '' : 'Invalid phone number format'
    }

    setValidationErrors(newValidationErrors)
  }

  const handleStatusChange = e => {
    setAddFiled({ ...addFiled, status: e.target.checked })
  }

  const callApiFunc = async () => {
    await callApi({ uriEndPoint: viewEndPoint, pathParams: { id: props?.match?.params?.id || null } })
      .then(res => {
        setAddFiled(res?.data || '')
        setIsDataFetched(true) // Data is successfully fetched
      })
      .catch(e => console.log(e))
  }

  const shouldShowValidationErrors = isSubmitted || !isDataFetched

  useEffect(() => {
    callApiFunc()
  }, [])

  return (
    <>
      <Breadcrumb
        className='d-none d-md-inline-block'
        listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
      >
        <Breadcrumb.Item>
          <FontAwesomeIcon icon={faHome} />
        </Breadcrumb.Item>
        <Breadcrumb.Item>SHAH</Breadcrumb.Item>
        <Breadcrumb.Item href='/#/coordinator'>All Coordinator</Breadcrumb.Item>
        <Breadcrumb.Item active>Update Coordinator</Breadcrumb.Item>
        <Breadcrumb.Item active>{props?.match?.params?.id}</Breadcrumb.Item>
      </Breadcrumb>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Edit Coordinator</h4>
            {/* <button className='btn btn-primary' onClick={() => window.history.back()}>
              Back
            </button> */}
          </div>

          <form onSubmit={handleSubmit}>
            <div className='row gy-3'>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4' className='mb-1'>
                  Name *
                </label>
                <input
                  type='text'
                  name='name'
                  value={addFiled?.name || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  placeholder='Name'
                  maxLength={150}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4' className='mb-1'>
                  University Id
                </label>
                <input
                  type='text'
                  name='university_id'
                  value={addFiled?.university_id?.name || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  placeholder='university id'
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4' className='mb-1'>
                  Email *
                </label>
                <input
                  type='email'
                  name='email'
                  value={addFiled?.email || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  maxLength={60}
                  placeholder='name@company.com'
                />
                {shouldShowValidationErrors && validationErrors.email && (
                  <small style={{ color: 'red' }}>{validationErrors.email}</small>
                )}
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4' className='mb-1'>
                  Phone number *
                </label>
                <input
                  type='text'
                  name='phone'
                  value={addFiled?.phone || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  maxLength={10}
                  placeholder='345678910'
                />
                {shouldShowValidationErrors && validationErrors.phone && (
                  <small style={{ color: 'red' }}>{validationErrors.phone}</small>
                )}
              </div>

              <div className='form-group col-md-6'>
                <label htmlFor='inputState' className='mb-1'>
                  Status
                </label>
                <Form.Check // prettier-ignore
                  type='switch'
                  id='custom-switch'
                  data-toggle='modal'
                  data-target='#exampleModalCenterStatus'
                  checked={addFiled.status}
                  onChange={handleStatusChange}
                />
              </div>
            </div>

            <div className='d-flex align-items-center  mt-3'>
              <Button type='submit' className='btn btn-primary mt-2 me-2'>
                Submit
              </Button>
              <Button className='btn btn-primary mt-2' onClick={() => window.history.back()}>
                Back
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default EditCoordinator
